/**
 * @ComponentFor ListLayoutBlock
 */
import React from 'react';
import ListLayoutBlockType from './ListLayoutBlock.type';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import { EpiProperty, epiPropertyValue, Breakpoint } from '@avensia/scope';
import * as style from 'Shared/Style';
import FadeIntoView from 'Shared/Animations/FadeIntoView';

type PropType = ListLayoutBlockType;

type HexagonSvgPropType = {
  color?: string;
};

type HexagonContainerPropType = {
  blocksPerRow?: number;
  multiColored?: boolean;
  index: number;
  children: React.ReactNode;
};

type ListLayoutContentAreaPropType = React.HTMLAttributes<HTMLElement> & {
  multiColored?: boolean;
  blocksPerRow?: number;
  children?: React.ReactNode;
};

const H2 = styled.h2({
  margin: {
    top: '3em',
    bottom: '1.5em',
  },
});

const Hexagon = ({ color }: HexagonSvgPropType) => (
  <>
    <styled.Svg
      viewBox="0 0 726 628"
      css={{
        fill: color ? color : style.primaryDark,
        fillOpacity: 0.1,
        stroke: 'none',
        // stroke: color ? color : style.primary,
        // strokeWidth: 3,
        // strokeOpacity: 0.25,
        position: 'absolute',
        top: '1.4rem',
        left: '2rem',
        width: '100%',
      }}
    >
      <polygon points="723,314 543,625.769145 183,625.769145 3,314 183,2.230855 543,2.230855 723,314" />
    </styled.Svg>
    <styled.Svg
      viewBox="0 0 726 628"
      css={{
        fill: color ? color : style.primaryDark,
        fillOpacity: 0.9,
        stroke: 'none',
        mixBlendMode: 'darken',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1,
      }}
    >
      <polygon points="723,314 543,625.769145 183,625.769145 3,314 183,2.230855 543,2.230855 723,314" />
    </styled.Svg>
  </>
);

type HeightRegulatorDummyType = {
  blocksPerRow?: number;
  childCount: number;
};

const blockSize = 25.8; // The size of a block on Medium and up, in %
const blockSizeMobile = 18; // The size of a block on mobile (below Medium), in REMS
const colors = [style.ORANGE, style.GREEN, style.primaryDark];

const HeightRegulatorDummy = ({ childCount, blocksPerRow }: HeightRegulatorDummyType) => (
  <styled.Div
    css={{
      margin: {
        top: `${blockSizeMobile * 0.79 * childCount}rem`,
      },
      ...media(style.mediaMinQueries[Breakpoint.Tiny], {
        margin: {
          top: `${blockSizeMobile * 0.77 * childCount}rem`,
        },
      }),
      ...media(style.mediaMinQueries[Breakpoint.Small], {
        margin: {
          top: `${blockSizeMobile * 0.56 * childCount +
            blockSizeMobile * 0.5 -
            blockSizeMobile * 0.5 * Math.floor((childCount - 1) / blocksPerRow)}rem`,
        },
      }),
      ...media(style.mediaMinQueries[Breakpoint.Medium], {
        margin: {
          top: `${blockSize * 1.05 * Math.ceil(childCount / blocksPerRow)}%`,
        },
      }),
    }}
  />
);

// Used for setting the height of the container
const HexagonContainer = ({ children, blocksPerRow, multiColored, index }: HexagonContainerPropType) => (
  <>
    <Hexagon color={multiColored ? colors[index % blocksPerRow] : style.primaryMedium} />
    <styled.Div
      css={{
        position: 'absolute',
        zIndex: 2,
        top: '0%',
        bottom: '20%',
        left: '22%',
        right: '18%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
        overflow: 'hidden',
        color: 'rgba(255,255,255,0.8)',
        ...media(style.mediaMinQueries[Breakpoint.Tiny], {
          fontSize: '110%',
        }),
        ...media(style.mediaMinQueries[Breakpoint.Small], {
          fontSize: '100%',
        }),
        ...media(style.mediaMinQueries[Breakpoint.Large], {
          fontSize: '110%',
        }),
        lineHeight: '1.2em',
      }}
      className="ListLayoutBlockContent"
    >
      {children}
    </styled.Div>
  </>
);

const ListLayoutContentArea = ({
  children,
  multiColored,
  blocksPerRow,
  ...restProps
}: ListLayoutContentAreaPropType) => {
  const childArray = React.Children.toArray(children);
  const childCount = childArray.length;
  blocksPerRow = Math.round(blocksPerRow);
  if (blocksPerRow < 3) {
    blocksPerRow = 3;
  }
  if (blocksPerRow > 4) {
    blocksPerRow = 4;
  }
  const wavyRows = true;
  return (
    <styled.Div
      css={{
        width: '110%',
        margin: {
          x: 'auto',
        },
        ...media(style.mediaMinQueries[Breakpoint.Tiny], {
          width: '100%',
          margin: {
            x: 'auto',
          },
        }),
        ...media(style.mediaMinQueries[Breakpoint.Medium], {
          width: '114%',
          margin: {
            x: '-7%',
          },
        }),
        overflow: 'hidden',
      }}
      {...restProps}
    >
      <styled.Div
        css={{
          display: 'inline-block', // Needed to get the HeightRegulatorDummy positioned properly
          position: 'relative',
          width: '100%',
        }}
      >
        <HeightRegulatorDummy childCount={childCount} blocksPerRow={blocksPerRow} />
        {childArray.map((c, index) => {
          return (
            <FadeIntoView
              key={index}
              css={{
                position: 'absolute',
                width: `${blockSizeMobile * 0.85}rem`,
                height: `${blockSizeMobile * 0.85}rem`,
                top: `${blockSizeMobile * 0.78 * index}rem`,
                left: `calc(${(100 * (index % blocksPerRow)) / (blocksPerRow - 1)}% - ${(blockSizeMobile *
                  0.85 *
                  (index % blocksPerRow)) /
                  (blocksPerRow - 1)}rem)`,
                ...media(style.mediaMinQueries[Breakpoint.Tiny], {
                  width: `${blockSizeMobile}rem`,
                  height: `${blockSizeMobile}rem`,
                  top: `${blockSizeMobile * 0.74 * index}rem`,
                  left: `calc(${(100 * (index % blocksPerRow)) / (blocksPerRow - 1)}% - ${(blockSizeMobile *
                    (index % blocksPerRow)) /
                    (blocksPerRow - 1)}rem)`,
                }),
                ...media(style.mediaMinQueries[Breakpoint.Small], {
                  top: `${blockSizeMobile * 0.56 * index -
                    blockSizeMobile * 0.5 * Math.floor(index / blocksPerRow)}rem`,
                }),
                ...media(style.mediaMinQueries[Breakpoint.Medium], {
                  width: `${blockSize}%`,
                  height: `${100 / Math.ceil(childCount / blocksPerRow)}%`,
                  top: `${(98 / Math.ceil(childCount / blocksPerRow)) * Math.floor(index / blocksPerRow) +
                    ((blockSize * 0.45) / Math.ceil(childCount / blocksPerRow)) *
                      (index % (wavyRows ? 2 : blocksPerRow))}%`,
                  left: `${blockSize * 0.82 * ((index % blocksPerRow) + (4 - blocksPerRow) / 2) +
                    blockSize * 0.36 * (Math.floor(index / blocksPerRow) % 2)}%`,
                }),
              }}
            >
              <HexagonContainer multiColored={multiColored} blocksPerRow={blocksPerRow} index={index}>
                {c}
              </HexagonContainer>
            </FadeIntoView>
          );
        })}
      </styled.Div>
    </styled.Div>
  );
};

class ListLayoutBlock extends React.Component<PropType> {
  constructor(props: PropType) {
    super(props);
  }

  render() {
    return (
      <>
        {this.props.heading && <EpiProperty component={H2} for={this.props.heading} />}
        <EpiProperty
          for={this.props.items}
          component={innerProps => (
            <ListLayoutContentArea
              multiColored={!!epiPropertyValue(this.props.multiColored)}
              blocksPerRow={epiPropertyValue(this.props.blocksPerRow)}
              {...innerProps}
            />
          )}
        />
      </>
    );
  }
}

export default ListLayoutBlock;

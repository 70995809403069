/**
 * @ComponentFor TextBlock
 */
import React from 'react';
import { EpiProperty, epiPropertyValue } from '@avensia/scope';
import TextBlockType from './TextBlock.type';
import { styled, StyledComponent, WithInnerRefProp } from '@glitz/react';

export default function TextBlock(props: TextBlockType) {
  let Container: StyledComponent<WithInnerRefProp<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;
  const image = epiPropertyValue(props.imageUrl);

  if (image) {
    Container = styled.div({
      backgroundImage: 'url(' + epiPropertyValue(props.imageUrl) + ')',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      justifyContent: 'center',
    });
  } else {
    Container = styled.div({
      display: 'flex',
      justifyContent: 'center',
    });
  }

  return (
    <Container>
      <EpiProperty for={props.html} />
    </Container>
  );
}

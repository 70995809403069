/**
 * @ComponentFor RowLayoutBlockViewModel
 */
import React from 'react';
import { EpiProperty, supportsComponentProp, ComponentType } from '@avensia/scope';
import RowLayoutBlockType from './RowLayoutBlockViewModel.type';
import BoxLayout from 'Shared/BoxLayout';
import { Basic, Appearance as PageAppearance } from 'Shared/PageLayout';
import { styled } from '@glitz/react';
import FadeIntoView from 'Shared/Animations/FadeIntoView';

type PropType = RowLayoutBlockType & {
  component?: ComponentType;
};

type RowLayoutContentAreaPropType = {
  elementProps: React.HTMLAttributes<HTMLElement> & {
    children?: React.ReactNode;
  };
  componentProps: PropType;
};

const H1 = styled.h2({
  margin: {
    top: '3em',
    bottom: '1em',
  },
});

const RowLayoutContentArea = ({
  elementProps,
  componentProps: { component: Component = 'div', ...componentProps },
}: RowLayoutContentAreaPropType) => {
  return (
    <Component {...elementProps}>
      <BoxLayout layout={componentProps.layout} spacingBetweenBlocks={componentProps.spacingBetweenBlocks}>
        {React.Children.toArray(elementProps.children).map((c, i) => (
          <FadeIntoView key={i}>{c}</FadeIntoView>
        ))}
      </BoxLayout>
    </Component>
  );
};

export default supportsComponentProp(
  class RowLayoutBlock extends React.Component<PropType> {
    elementComponent: React.StatelessComponent<React.HTMLAttributes<HTMLElement>> = props => (
      <RowLayoutContentArea elementProps={props} componentProps={this.props} />
    );
    render() {
      /**
       * Applying `PageAppearance.Spacing` only when editor explicitly want spacing around
       * blocks will promote the row layout block as master for spacing (will disable spacing for
       * descendant blocks). This because it's nearly impossible to mix blocks that should have
       * spacing with blocks that should not have spacing for all different viewports in a row
       * layout block. So it's up to the editor to make sure it looks nice.
       */
      return (
        <>
          {this.props.heading && <H1>{this.props.heading}</H1>}
          <Basic appearance={this.props.spacingBetweenBlocks && PageAppearance.Gap}>
            <EpiProperty for={this.props.block.items} component={this.elementComponent} />
          </Basic>
        </>
      );
    }
  },
);

/**
 * @ComponentFor VideoBlock
 */
import React from 'react';
import { styled } from '@glitz/react';
import { EpiProperty, epiPropertyValue } from '@avensia/scope';
import VideoBlockType from './VideoBlock.type';
import VideoSource from './VideoSource.type';
import AspectRatio, { Ratio } from './../../AspectRatio';
import * as style from 'Shared/Style';

type PropType = VideoBlockType;

const getVideoUrl = (videoId: string, videoSource: VideoSource) => {
  if (videoSource === 'youTube') {
    return `//www.youtube.com/embed/${videoId}`;
  } else if (videoSource === 'vimeo') {
    return `//player.vimeo.com/video/${videoId}`;
  }
};
type StateType = {
  PlayVideo: boolean;
};
class VideoBlock extends React.Component<PropType, Partial<StateType>> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      PlayVideo: false,
    };
  }
  HandlePlayVideo = () => {
    this.setState({ PlayVideo: true });
  };

  render() {
    const VideoIframe = styled.iframe({
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      width: '100%',
    });

    const VideoContainer = styled(AspectRatio, {
      position: 'relative',
      backgroundImage: 'url(' + epiPropertyValue(this.props.thumbnail) + ')',
      backgroundSize: 'cover',
      ':hover': {
        cursor: 'pointer',
      },
    });

    const PlayButton = styled.div({
      background: 'transparent',
      borderRadius: '50%',
      fontSize: '2em',
      height: '80px',
      margin: {
        x: 'auto',
      },
      padding: {
        xy: 0,
      },
      border: {
        xy: {
          style: 'solid',
          width: '1px',
          color: style.primaryDark,
        },
      },
      position: 'absolute',
      textAlign: 'center',
      textIndent: '0.1em',
      width: '80px',
      top: `calc(50% - 40px)`,
      left: `calc(50% - 40px)`,

      '::after': {
        border: {
          top: {
            style: 'solid',
            width: '1em',
            color: 'transparent',
          },
          right: {
            style: 'solid',
            width: 0,
            color: 'transparent',
          },
          bottom: {
            style: 'solid',
            width: '1em',
            color: 'transparent',
          },
          left: {
            style: 'solid',
            width: '1.732em',
            color: style.primaryDark,
          },
        },
        content: '""',
        fontSize: '0.75em',
        height: 0,
        margin: {
          top: '-1em',
          right: 0,
          bottom: 0,
          left: '-0.75em',
        },
        top: '50%',
        position: 'absolute',
        width: 0,
      },
    });

    return (
      <div>
        {epiPropertyValue(this.props.header) && <EpiProperty for={this.props.header} component="h2" />}
        <VideoContainer onClick={this.HandlePlayVideo} ratio={Ratio.SixteenToNine}>
          <PlayButton />
          {this.state.PlayVideo ? (
            <VideoIframe
              frameBorder="0"
              allowFullScreen={epiPropertyValue(this.props.allowFullscreen)}
              src={getVideoUrl(epiPropertyValue(this.props.videoId), epiPropertyValue(this.props.videoSource))}
            />
          ) : (
            <span />
          )}
        </VideoContainer>
        {epiPropertyValue(this.props.description) && <EpiProperty for={this.props.description} />}
      </div>
    );
  }
}
export default VideoBlock;

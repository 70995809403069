/**
 * @ComponentFor HtmlBlock
 */
import React from 'react';
import { EpiProperty, epiPropertyValue } from '@avensia/scope';
import HtmlBlockType from './HtmlBlock.type';

export default function HtmlBlock(props: HtmlBlockType) {
  return (
    <EpiProperty propertyComponentProps={{ keepImageSizes: epiPropertyValue(props.displayImage) }} for={props.html} />
  );
}

import * as React from 'react';
import * as style from 'Shared/Style';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import AttachmentBlockType from 'Shared/Blocks/Attachment/AttachmentBlock.type';
import { epiPropertyValue, Breakpoint } from '@avensia/scope';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import Download from 'Shared/Icon/Download';

interface Props {
  attachment: AttachmentBlockType;
}

const Attachments: React.SFC<Props> = props => {
  return (
    <>
      <TitleText>{epiPropertyValue(props.attachment.title)}</TitleText>
      <ButtonWrapper>
        <FileButton
          onClick={() => window.open(epiPropertyValue(props.attachment.file), '_blank')}
          appearance={ButtonAppearance.Link}
          color={style.primaryDark}
        >
          <Download style={{ marginRight: '10px', position: 'relative', top: '2px' }} />
          {epiPropertyValue(props.attachment.buttonText)}
        </FileButton>
      </ButtonWrapper>
    </>
  );
};

const TitleText = styled.h3({
  marginTop: '15px',
  marginBottom: 0,
  fontSize: '14px',
  lineHeight: '21px',
  fontWeight: 300,
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    fontSize: '16px',
  }),
});

const ButtonWrapper = styled.div({});

const FileButton = styled(Button, {
  marginTop: '2px',
  height: 'auto',
  fontSize: '14px',
  fontWeight: 'normal',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    fontSize: '15px',
  }),
});

export default Attachments;

/**
 * @ComponentFor AttachmentBlock
 */
import React from 'react';
import AttachmentBlockType from './AttachmentBlock.type';
import Attachments from './Attachments';

export default function AttachmentBlock(props: AttachmentBlockType) {
  return <Attachments attachment={props} />;
}

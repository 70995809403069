/**
 * @ComponentFor SiteBannerBlock
 */
import React from 'react';
import SiteBannerBlockType from './SiteBannerBlock.type';
import { EpiProperty, epiPropertyValue, Breakpoint } from '@avensia/scope';
import SiteBanner from './';
import { styled } from '@glitz/react';
import * as style from 'Shared/Style';
import { Appearance as ButtonAppearance } from 'Shared/Button';
import { media } from '@glitz/core';
import HexagonButton from 'Shared/Button/Hexagon';
import FadeIntoView from 'Shared/Animations/FadeIntoView';

const ContentContainer = styled.div({});

type BasePropType = {
  children: React.ReactNode;
  invertText?: boolean;
};

const Base = ({ invertText, children }: BasePropType) => (
  <styled.Div
    css={{
      position: 'relative',
      color: invertText ? style.BLACK : style.WHITE,
      textShadow: invertText ? '0 0 10px rgba(255,255,255,0.5)' : '0 0 10px rgba(0,0,0,0.7)',
      padding: {
        bottom: '20px',
      },
      maxWidth: style.maxContentWidth - 480,
      margin: {
        x: 'auto',
      },
      ...media(style.mediaMinQueries[Breakpoint.Small], {
        padding: {
          bottom: 0,
        },
      }),
    }}
  >
    {children}
  </styled.Div>
);

const Banner = styled.div({
  padding: {
    top: '4em',
    bottom: '4em',
  },
});

const ButtonWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: {
    top: '4em',
    bottom: '2em',
    right: '-2em',
  },
});

const BackgroundImage = styled.div({
  position: 'absolute',
  zIndex: -1,
  top: 0,
  bottom: 0,
  width: '100vw',
  left: '50%',
  right: '50%',
  margin: {
    x: '-50vw',
  },
  overflow: 'hidden',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

const BackgroundVideo = styled.video({
  position: 'absolute',
  zIndex: -1,
  top: 0,
  bottom: 0,
  objectFit: 'cover',
  objectPosition: 'center',
  width: '100%',
});

export default function SiteBannerBlock(props: SiteBannerBlockType) {
  return (
    <SiteBanner className="site-banner-block">
      <FadeIntoView>
        <Base invertText={epiPropertyValue(props.invertText)}>
          <BackgroundImage style={{ backgroundImage: 'url(' + epiPropertyValue(props.backgroundImage) + ')' }} />
          {epiPropertyValue(props.videoLocation) && (
            <BackgroundVideo autoPlay loop muted preload="auto">
              <source src={epiPropertyValue(props.videoLocation)} type="video/mp4" />
            </BackgroundVideo>
          )}
          <Banner>
            <ContentContainer>
              <EpiProperty for={props.text} />
            </ContentContainer>
            <ButtonWrapper>
              {epiPropertyValue(props.button1Link) && (
                <HexagonButton
                  appearance={
                    epiPropertyValue(props.invertText) ? ButtonAppearance.Primary : ButtonAppearance.Highlight
                  }
                  to={epiPropertyValue(props.button1Link)}
                >
                  <span> {epiPropertyValue(props.button1Text)}</span>
                </HexagonButton>
              )}
              {epiPropertyValue(props.button2Link) && (
                <HexagonButton
                  appearance={
                    epiPropertyValue(props.invertText) ? ButtonAppearance.Primary : ButtonAppearance.Highlight
                  }
                  to={epiPropertyValue(props.button2Link)}
                >
                  <span> {epiPropertyValue(props.button2Text)}</span>
                </HexagonButton>
              )}
              {epiPropertyValue(props.button3Link) && (
                <HexagonButton
                  appearance={
                    epiPropertyValue(props.invertText) ? ButtonAppearance.Primary : ButtonAppearance.Highlight
                  }
                  to={epiPropertyValue(props.button3Link)}
                >
                  <span> {epiPropertyValue(props.button3Text)}</span>
                </HexagonButton>
              )}
            </ButtonWrapper>
          </Banner>
        </Base>
      </FadeIntoView>
    </SiteBanner>
  );
}

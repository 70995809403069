/**
 * @ComponentFor TextAndPictureBlock
 */
import React from 'react';
import { styled } from '@glitz/react';
import TextAndPictureBlockType from './TextAndPictureBlock.type';
import { EpiProperty, epiPropertyValue, Breakpoint } from '@avensia/scope';
import { Appearance as ButtonAppearance } from 'Shared/Button';
import * as style from 'Shared/Style';
import Viewport from 'Shared/Viewport';
import { Main, Appearance } from 'Shared/PageLayout';
import { media } from '@glitz/core';
import HexagonButton from 'Shared/Button/Hexagon';
import FadeIntoView from 'Shared/Animations/FadeIntoView';

const MainContainer = styled.div({
  width: '100%',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    display: 'flex',
    justifyContent: 'space-between',
  }),
  margin: {
    y: 30,
  },
});

type OrderPropType = {
  children: React.ReactNode;
  imageOnLeft?: boolean;
};

const TextCell = ({ imageOnLeft, children }: OrderPropType) => (
  <styled.Div
    css={{
      textAlign: 'left',
      ...media(style.mediaMinQueries[Breakpoint.Small], {
        flexBasis: '50%',
        flexGrow: 0,
        flexShrink: 1,
        order: imageOnLeft ? 2 : 0,
        zIndex: 2,
      }),
    }}
  >
    {children}
  </styled.Div>
);

const ImageCell = ({ imageOnLeft, children }: OrderPropType) => (
  <styled.Div
    css={{
      minHeight: '300px',
      overflow: 'hidden',
      zIndex: 2,
      position: 'relative',
      ...media(style.mediaMinQueries[Breakpoint.Small], {
        flexBasis: '50%',
        flexGrow: 0,
        flexShrink: 1,
        order: imageOnLeft ? 0 : 2,
        zIndex: 2,
      }),
    }}
  >
    {children}
  </styled.Div>
);

const SpacerCell = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    flexBasis: '40px',
    flexGrow: 0,
    flexShrink: 0,
    order: 1,
  }),
});

type HexPropType = {
  src: string;
  hexagonImage?: boolean;
};

const Image = ({ src, hexagonImage }: HexPropType) => (
  <styled.Img
    src={src}
    css={{
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      clipPath: hexagonImage ? 'polygon(9% 0, 84% 0, 100% 67%, 91% 100%, 16% 100%, 0 33%)' : 'none',
    }}
  />
);

const ButtonWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: {
    top: '3em',
    bottom: '1em',
    right: '-2em',
  },
});

type PropType = TextAndPictureBlockType;

class TextAndPictureBlock extends React.Component<PropType> {
  constructor(props: PropType) {
    super(props);
  }

  render() {
    return (
      <Main appearance={Appearance.BackgroundImage}>
        <Viewport>
          {(isCompact: boolean) => (
            <>
              <MainContainer>
                <TextCell imageOnLeft={epiPropertyValue(this.props.imageOnLeft)}>
                  <FadeIntoView>
                    <EpiProperty for={this.props.mainContent} />
                    {this.props.buttonLink && (
                      <ButtonWrapper>
                        <HexagonButton
                          appearance={ButtonAppearance.Primary}
                          to={epiPropertyValue(this.props.buttonLink)}
                        >
                          <span> {epiPropertyValue(this.props.buttonText)}</span>
                        </HexagonButton>
                      </ButtonWrapper>
                    )}
                  </FadeIntoView>
                </TextCell>
                <SpacerCell />
                <ImageCell imageOnLeft={epiPropertyValue(this.props.imageOnLeft)}>
                  <FadeIntoView>
                    <Image
                      src={epiPropertyValue(this.props.image)}
                      hexagonImage={epiPropertyValue(this.props.hexagonImage)}
                    />
                  </FadeIntoView>
                </ImageCell>
              </MainContainer>
            </>
          )}
        </Viewport>
      </Main>
    );
  }
}

export default TextAndPictureBlock;

/**
 * @ComponentFor PreviewViewModel
 */
import React from 'react';
import { EpiProperty } from '@avensia/scope';
import PreviewViewModel from './PreviewViewModel.type';
import { Main, Section, Appearance as PageAppearance } from 'Shared/PageLayout';

export default (props: PreviewViewModel) => (
  <Main appearance={PageAppearance.Narrow}>
    <Section>
      {props.blockPreviews.map((preview, i) => (
        <div key={i}>
          {!!preview.displayOptionName && <h2>{preview.displayOptionName}</h2>}
          <EpiProperty for={preview.block} propertyComponentProps={{ isPreview: true }} />
        </div>
      ))}
    </Section>
  </Main>
);

import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { Breakpoint } from '@avensia/scope';
import connect from '../connect';
import * as style from '../Style';
import { pixelsToUnit } from '../Style';

const SPACING_DEFAULT = style.huge;

const fractions = {
  '1:2': 1 / 2,
  '1:3': 1 / 3,
  '1:4': 1 / 4,
  '2:3': 2 / 3,
  '3:4': 3 / 4,
};

export type LayoutAliasType = keyof typeof fractions;

type LayoutColumnType = LayoutAliasType[] & {
  0: LayoutAliasType;
  1: LayoutAliasType[] & {
    0: LayoutAliasType;
    1: LayoutAliasType;
  };
};

export type LayoutType = LayoutAliasType | LayoutColumnType;

function spacingDefaulter(value: boolean | string) {
  return value === true ? pixelsToUnit(SPACING_DEFAULT) : value;
}

function isThin(fraction: number) {
  return fraction <= 1 / 3;
}

type BlockEntityType = {
  fraction: number;
  element: React.ReactChild;
};

type ColumnEntityType = {
  fraction: number;
  columns: BlockEntityType[];
};

function sort(entities: BlockEntityType[]) {
  return entities.sort((a, b) => b.fraction - a.fraction);
}

function convert(
  row: LayoutType[],
  children: React.ReactChild[],
  currentBreakpoint: number,
  allowSorting?: boolean,
): Array<BlockEntityType | ColumnEntityType> {
  if (currentBreakpoint > Breakpoint.Small) {
    return row.map(entry => {
      if (typeof entry === 'string') {
        return {
          fraction: fractions[entry],
          element: children.shift(),
        };
      }

      return {
        fraction: fractions[entry[0]],
        columns: entry[1].map(entry => ({
          fraction: fractions[entry],
          element: children.shift(),
        })),
      };
    });
  }

  // Flatten all blocks to full or half width
  if (currentBreakpoint > Breakpoint.Micro) {
    const entities = row.reduce<BlockEntityType[]>((current, item) => {
      if (typeof item === 'string') {
        return [
          ...current,
          {
            fraction: isThin(fractions[item]) ? 0.5 : 1,
            element: children.shift(),
          },
        ];
      }

      const value = isThin(fractions[item[0]]) ? 0.5 : 1;
      return [
        ...current,
        ...item[1].map(() => ({
          fraction: value,
          element: children.shift(),
        })),
      ];
    }, []);

    return allowSorting ? sort(entities) : entities;
  }

  // Flatten all blocks to full width
  const entities = row.reduce<BlockEntityType[]>(
    (current, entry) =>
      typeof entry === 'string'
        ? [
            ...current,
            {
              fraction: 1,
              element: children.shift(),
            },
          ]
        : [
            ...current,
            ...entry[1].map(() => ({
              fraction: 1,
              element: children.shift(),
            })),
          ],
    [],
  );

  return allowSorting ? sort(entities) : entities;
}

function isColumn(entity: BlockEntityType | ColumnEntityType): entity is ColumnEntityType {
  return 'columns' in entity;
}

function wrap(spacingBetweenBlocks: boolean | string) {
  const spacing = spacingDefaulter(spacingBetweenBlocks);
  return (entity: BlockEntityType | ColumnEntityType, index: number): React.ReactNode => {
    return isColumn(entity) ? (
      <styled.Div
        key={index}
        css={{
          display: 'flex',
          flexDirection: 'column',
          flexBasis: `${entity.fraction * 100}%`,
          flexGrow: 1,
          minWidth: 0,
          textAlign: 'left',
        }}
      >
        {entity.columns.map(wrap(spacingBetweenBlocks))}
      </styled.Div>
    ) : (
      <styled.Div
        key={index}
        css={{
          marginTop: '2em',
          marginBottom: '2em',
          ...(spacing && {
            marginLeft: spacing,
          }),
          flexBasis: spacing ? `calc(${entity.fraction * 100}% - ${spacing})` : `${entity.fraction * 100}%`,
          flexGrow: 1,
          minWidth: 0,
          textAlign: 'left',
        }}
      >
        {entity.element}
      </styled.Div>
    );
  };
}

type RequiredPropType = {
  layout: LayoutType[];
  spacingBetweenBlocks?: boolean | string;
  allowSorting?: boolean;
};

type ConnectStateType = {
  currentBreakpoint: number;
};

type PropType = StyledProps & RequiredPropType & ConnectStateType;

class BoxLayout extends React.Component<PropType> {
  render() {
    const children = React.Children.toArray(this.props.children);
    const entities = convert(this.props.layout, children, this.props.currentBreakpoint, this.props.allowSorting);
    const spacing = spacingDefaulter(this.props.spacingBetweenBlocks);

    return (
      <styled.Div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          ...(spacing && {
            marginLeft: `-${spacing}`,
          }),
          ...(this.props.currentBreakpoint > Breakpoint.Small && {
            flexWrap: 'nowrap',
          }),
        }}
      >
        {entities.map(wrap(this.props.spacingBetweenBlocks))}
      </styled.Div>
    );
  }
}

export default styled(
  connect(
    (state): ConnectStateType => ({
      currentBreakpoint: state.currentBreakpoint,
    }),
  )(BoxLayout),
);

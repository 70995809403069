/**
 * @ComponentFor ImageBlockViewModel
 */
import React from 'react';
import { styled } from '@glitz/react';
import { Link, epiPropertyValue } from '@avensia/scope';
import connect from 'Shared/connect';
import Image from '../../Image';
import ImageBlockPropType from './ImageBlockViewModel.type';

type ConnectStateType = {
  currentBreakpoint: number;
};
type PropType = ImageBlockPropType & ConnectStateType;

const ImageCover = styled(Image, {});
export default connect(
  (state): ConnectStateType => ({
    currentBreakpoint: state.currentBreakpoint,
  }),
)(function ImageBlock(props: PropType) {
  const linkUrl = epiPropertyValue(props.block.linkUrl);
  const image = (
    <ImageCover
      src={epiPropertyValue(props.block.imageUrl)}
      alt={epiPropertyValue(props.block.altText)}
      pendingPreview
    />
  );
  return linkUrl ? (
    <div className="ImageBlockImageContainer">
      <Link to={linkUrl}>{image}</Link>
    </div>
  ) : (
    <div className="ImageBlockImageContainer">{image}</div>
  );
});
